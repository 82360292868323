import { defineStore } from 'pinia'

export const useCoverage = defineStore('coverage', {
	state: () => ({
		coverage: {},

		loaded: false
	}),
	getters: {
		coverage() {
			return this.$state.coverage
		},
		loaded() {
			return this.$state.loaded
		}
	},
	actions: {
		updateCoverage(memberId, payload) {
			this.$state.coverage[memberId] = payload
			this.$state.loaded = true
		},
		resetCoverage() {
			this.$state.coverage = {}
			this.$state.loaded = false
		}
	},
	persist: {
		storage: sessionStorage
	}
})
