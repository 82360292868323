<template>
	<v-container class="fill-height justify-center">
		<img src="@/assets/logo-small.svg" />
	</v-container>
</template>

<script>
import qs from 'qs'
import { logout } from '@/services/api/axios.js'

export default {
	created() {
		logout()

		const redirect_uri = window.location.origin + '/auth-success'
		const uri = qs.stringify({ redirect_uri })
		const state = Math.random().toString(36).substring(2, 12)
		location.href = `${import.meta.env.VITE_OIDC_AUTH_URL}/authorize?client_id=${
			import.meta.env.VITE_CLIENT_KEY
		}&scope=openid%20profile%20offline_access&${uri}&response_type=code&state=${state}`
	}
}
</script>
