import { searchCoverage } from '@/services/api/fhirAPI/coverage/coverage.js'
import { useCoverage } from '@/stores/coverage/coverage.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'

// Retrieves store if it's populated.
// If this the first time accessing the store (store is empty),
// then it makes the API call and updates/returns the store
export async function retrieveCoverage() {
	const store = useCoverage()
	const planStore = useSelectedPlan()

	function twelveFromToday() {
		const today = new Date()
		const dateMax = new Date(today)
		const newDate = new Date(dateMax.setMonth(dateMax.getMonth() - 12))

		return newDate
	}

	function checkType(planType) {
		if (planType === 'hip') {
			return 'medical'
		} else {
			return planType
		}
	}

	function withinXMonths(val, months) {
		const today = new Date()
		const dateMax = new Date(today)
		dateMax.setMonth(dateMax.getMonth() - months)
		const dateToCheck = new Date(val)
		return dateMax <= dateToCheck
	}

	if (!store.loaded) {
		await searchCoverage(
			planStore.contractId,
			`&versionId=2&_count=50&period=ge${twelveFromToday().getFullYear()}-01-01`
		)
			.then((res) => {
				if (res.total === 0) return
				res.loaded = true
				res.entry.forEach((covItem) => {
					const planType = checkType(covItem.resource.type.coding[0].code.toLowerCase())
					if (
						covItem.resource.status === 'active' &&
						planType === planStore.contractType?.toLowerCase() &&
						!(covItem.resource.id in store.coverage)
					) {
						store.updateCoverage(covItem.resource.id, covItem.resource)
					} else if (
						covItem.resource.status === 'cancelled' &&
						planType === planStore.contractType?.toLowerCase() &&
						!(covItem.resource.id in store.coverage) &&
						withinXMonths(covItem.resource.period.end, 12)
					) {
						store.updateCoverage(covItem.resource.id, covItem.resource)
					}
				})
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return store
}
